/**
 * Salta
 *
 * @author Robuust
 * @author Gijs Stegehuis <gijs@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['sameAddress', 'shippingAddress', 'billingAddress', 'paymentIssuers', 'submit'];

  /**
   * Connect.
   */
  connect() {
    if (this.hasSameAddressTarget && !this.sameAddressTarget.checked) {
      this.shippingAddressTarget.classList.remove('hidden');
      this.shippingAddressTarget.disabled = false;
    }
  }

  /**
   * Show/hide shipping address.
   */
  toggleShippingAddress() {
    this.shippingAddressTarget.classList.toggle('hidden');
    this.shippingAddressTarget.disabled = !this.shippingAddressTarget.disabled;
  }

  /**
   * Show/hide billing address.
   */
  toggleBillingAddress() {
    this.billingAddressTarget.classList.toggle('hidden');
    this.billingAddressTarget.disabled = !this.shippingAddressTarget.disabled;
  }

  /**
   * Show/hide payment issuers
   *
   * @param {Object} e
   */
  togglePaymentIssuers(e) {
    this.paymentIssuersTarget.classList.toggle('hidden', e.target.value !== 'ideal');
    this.paymentIssuersTarget.querySelector('select').disabled = e.target.value !== 'ideal';
  }

  /**
   * Disables submit button
   *
   * @param {Event} e
   */
  disableSubmit(e) {
    if (this.hasSubmitTarget) {
      if (this.submitTarget.disabled) {
        e.preventDefault();
      }

      this.submitTarget.disabled = true;
    }
  }
}
