/**
 * Salta
 *
 * @author Robuust
 * @author Bob Olde Hampsink <bob@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgVideo from 'lightgallery/plugins/video';
import lgHash from 'lightgallery/plugins/hash';

/**
 * Controller for gallery.
 */
export default class extends Controller {
  static values = {
    id: {
      type: String,
      default: '1',
    },
    selector: {
      type: String,
      default: '[data-src]',
    },
    thumbnails: {
      type: Boolean,
      default: true,
    },
  };

  /**
   * Connect gallery.
   */
  connect() {
    this.lg = lightGallery(this.element, {
      galleryId: this.idValue,
      selector: this.selectorValue,
      thumbnail: this.thumbnailsValue,
      exThumbImage: 'data-src',
      getCaptionFromTitleOrAlt: false,
      download: false,
      youTubePlayerParams: {
        rel: 0,
        modestbranding: 1,
        color: 'red',
      },
      plugins: [lgThumbnail, lgFullscreen, lgVideo, lgHash],
      licenseKey: '00000000-00000000-00000000-00000000',
    });
  }

  /**
   * Disconnect gallery.
   */
  disconnect() {
    this.lg.destroy();
  }

  /**
   * Open gallery.
   *
   * @param {Event} event
   */
  open({ params }) {
    this.lg.openGallery(params.index || 0);
  }

  /**
   * Refresh gallery.
   */
  refresh() {
    this.lg.refresh();
  }
}
